<template>
  <b-container
    class="bv-example-row mb-3"
    fluid
  >
    <hr>
    <b-row class="mt-2">
      <b-col cols="3">
        <b-card>
          <h5>Rezervasyon Detayı</h5>
          <hr>
          <b-row>
            <b-col cols="4">
              <strong> Toplam Tutar: </strong>
              <p>
                {{ toplamTutar }} TL
              </p>
            </b-col>
            <b-col cols="4">
              <strong> Masked PAN: </strong>
              <p>
                {{ maskedPan }}
              </p>
            </b-col>
            <b-col cols="4">
              <strong> Kart Sahibi: </strong>
              <p>
                {{ kartSahibi }}
              </p>
            </b-col>
            <b-col cols="4">
              <strong> Giriş Tarihi: </strong>
              <p>
                {{ girisTarih }}
              </p>
            </b-col>
            <b-col cols="4">
              <strong>Çıkış Tarihi: </strong>
              <p>
                {{ cikisTarih }}
              </p>
            </b-col>
            <b-col cols="4">
              <strong>Gece Sayısı: </strong>
              <p>
                {{ period }}
              </p>
            </b-col>
            <b-col cols="12">
              <strong> IP Adres: </strong>
              <p>
                {{ ip }}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="9">
        <b-card>
          <b-row class="mt-50">
            <b-col cols="6">
              <h4>Konuk Listesi</h4>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="myButton border-0"
                to="/sanalPos&FaturaKayitlari"
                :style="{backgroundColor:$store.getters.bgInfo}"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                />
                Listeye Geri Dön
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  İSİM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  SOY İSİM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  GİRİŞ TARİHİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ÇIKIŞ TARİHİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  TARİFE
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  FiYAT
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KONUK TÜRÜ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(s, index) in this.$store.getters.siparisDetayReturn.konukListesi"
                :key="index"
              >
                <td>{{ s.adi }}</td>
                <td>
                  {{ s.soyAdi }}
                </td>
                <td>{{ s.girisTarih }}</td>
                <td>{{ s.cikisTarih }}</td>
                <td>
                  {{
                    tarife[s.tarife].tarifeAdi
                  }}
                </td>
                <td>
                  {{ s.fiyat }}
                </td>
                <td>
                  {{
                    s.konukTuru == 1
                      ? "Yetişkin"
                      : s.konukTuru == 2
                        ? "Eş"
                        : s.konukTuru == 3 ? 'Ücretli Çocuk' : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
  },
  data() {
    return {
      siparisNo: this.$route.params.id,
    }
  },
  watch: {
    '$route.params.id': function (newVal) {
      this.$store.dispatch('siparisDetay', newVal)
    },
  },
  mounted() { },
  beforeDestroy() {
    this.$store.commit('clearSiparisDetay')
  },
  computed: {
    tarife() {
      if (this.$store.getters.tarife) {
        return this.$store.getters.tarife
      }
    },
    toplamTutar() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.paymentDetay) {
          return this.$store.getters.siparisDetayReturn.paymentDetay.aMount
        }
      }
    },
    maskedPan() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.paymentDetay) {
          return this.$store.getters.siparisDetayReturn.paymentDetay.maskedPan
        }
      }
    },
    kartSahibi() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.paymentDetay) {
          return this.$store.getters.siparisDetayReturn.paymentDetay.ccName
        }
      }
    },
    girisTarih() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.rezervasyonDetay) {
          return this.$store.getters.siparisDetayReturn.rezervasyonDetay.girisTarih
        }
      }
    },
    cikisTarih() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.rezervasyonDetay) {
          return this.$store.getters.siparisDetayReturn.rezervasyonDetay.cikisTarih
        }
      }
    },
    period() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.rezervasyonDetay) {
          return this.$store.getters.siparisDetayReturn.rezervasyonDetay.period
        }
      }
    },
    ip() {
      if (this.$store.getters.siparisDetayReturn) {
        if (this.$store.getters.siparisDetayReturn.rezervasyonDetay) {
          return this.$store.getters.siparisDetayReturn.rezervasyonDetay.ip
        }
      }
    },
    ...mapGetters('raporlar', { lop: 'kartSahibi' }),
    // veri() {
    //   return this.$store.getters["raporlar/masked"];
    // },
    // kartSahibii() {
    //   return this.$store.getters["raporlar/kartSahibi"];
    // },
  },
  created() {
    this.$store.dispatch('siparisDetay', this.$route.params.id).then((res, position = localStorage.getItem('otelSuccessReturn')) => {
      const mesaj = this.$store.getters.notificationSettings(res)
      if (mesaj) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Başarılı',
            text: mesaj,
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        }, { position })
      }
    }).catch(err => {
      const mesaj = this.$store.getters.notificationSettings(err)
      if (mesaj) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: mesaj,
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    })
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
.row {
  justify-content: end !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    padding: 3px;
    color: white;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th ,td {
    padding: 3px;
}
</style>
<style>
.content-wrapper {

}
</style>
